import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './index.module.scss';

const Menu = (props) => {
    const {t} = useTranslation();

    return (
        <nav className={`${styles.nav} ${props.mobileToggle? styles.nav_open:''}`}>
            <ul className={styles.nav_items}>
                {Object.values(props.menu).map((gender) => (
                    !['all', 'other', 'unisex'].includes(gender.slug) ? (
                        <li key={"header" + gender.slug} className={styles.nav_items_item} onMouseEnter={displayMenu}
                            onMouseLeave={closeMenu}>
                            <Link className={styles.nav_items_item_link}
                                  to={typeof gender.link !== 'undefined' ? "/" + props.currentLang + gender.link : "/" + props.currentLang + "/shop/" + gender.slug}
                                  onClick={closeMenu}>{gender['title_' + props.currentLang]}</Link>
                            { gender.childs.length > 0 && gender.childs ? (
                                <div className={styles.submenu}>
                                    <div className={styles.submenu_row}>
                                        { gender.genderchilds.length > 0 ? (
                                            <>
                                            <LeftCol styles={styles} items={gender.genderchilds} Lang={props.currentLang}
                                                     hover={true} link="shop"/>
                                            <div className={styles.submenu_col_2}>
                                                {gender.genderchilds.map((subgender, index) => (
                                                    <RightCol key={index + gender.slug} styles={styles} Lang={props.currentLang}
                                                              gender={subgender} keyData={index + gender.slug + 'a'}
                                                              active={index === 0 ? true : false} subgender={true}
                                                              slug={true} promo={false}/>
                                                ))}
                                            </div>
                                            </>
                                        ) : (
                                            <div className={styles.submenu_col_2}>
                                                { gender.childs ? (
                                                    <RightCol styles={styles} Lang={props.currentLang} gender={gender}
                                                              keyData={gender.slug + 'b'} slug={true} promo={false}/>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : null }
                        </li>
                    ) : null
                ))}

                <li className={styles.nav_items_item} onMouseEnter={displayMenu} onMouseLeave={closeMenu}>
                    <Link className={styles.nav_items_item_link}
                          to={"/" + props.currentLang + "/shop/all?attr=promo"}
                          onClick={closeMenu}>{t("SALE")}</Link>
                    <div className={styles.submenu}>
                        <div className={styles.submenu_row}>
                            <LeftCol styles={styles} items={props.pages} Lang={props.currentLang} hover={false} link="page"/>
                            <div className={styles.submenu_col_2}>
                                <RightCol styles={styles} Lang={props.currentLang} gender={props.menu} keyData={'sale'} active={true}
                                          subgender={true} slug={false} promo={true}/>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

function LeftCol(props) {
    const {styles, Lang, items, hover, link} = props

    const mouseEnterHendler = (e) => {
        Array.from(document.getElementsByClassName(`${styles.sub_gender}`)).map(item => (
            item.classList.remove(`${styles.sub_gender_active}`)
        ))
        Array.from(document.getElementsByClassName(`${styles[e.target.dataset.gen]}`)).map(item => (
            item.classList.add(`${styles.sub_gender_active}`)
        ))
    }

    return (
        <div className={styles.submenu_col_1}>
            <ul className={styles.submenu_col_1_items}>
                {items.map((item, index) => (
                    <li key={item.slug + item.id + index} className={styles.submenu_col_1_items_item}>
                        <Link
                            onMouseEnter={hover ? mouseEnterHendler : null}
                            data-gen={item.slug}
                            className={styles.submenu_col_1_items_item_link}
                            onClick={closeMenu}
                            to={"/" + Lang + "/" + link + "/" + item.slug}>{item['title_' + Lang]}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function RightCol(props) {
    const {styles, Lang, gender, keyData, active, subgender, slug, promo} = props
    const nav = (gender.childs) ? gender.childs : gender;

    return (
        <>
        {Object.values(nav).map((item, index) => (
            <React.Fragment key={(gender.slug ? gender.slug : item.slug) + item.id + keyData}>
                {item.slug !== 'all' && item.slug !== 'other' ? (
                    <div className={`
                ${styles.submenu_col_2_item}
                ${styles[gender.slug] ? styles[gender.slug] : ''}
                ${subgender ? styles.sub_gender : ''}
                ${active ? styles.sub_gender_active : ''}
                `}>
                        <Link className={styles.submenu_col_2_item_link}
                              to={"/" + Lang + "/shop/" + (slug ? gender.slug + "/" : '') + item.slug + (promo ? '?attr=promo' : '')}
                              onClick={closeMenu}>
                            {item['title_' + Lang]}
                        </Link>
                        <ul className={styles.submenu_col_2_item_list}>
                            {Object.values(item.childs).map((child) => (
                                <li key={(gender.slug ? gender.slug : item.slug) + item.id + child.id + keyData}
                                    className={styles.submenu_col_2_item_list_item}>
                                    <Link className={styles.submenu_col_2_item_list_item_link}
                                          to={"/" + Lang + "/shop/" + (slug ? gender.slug : item.slug) + "/" + linkUrlSplit(child.slug) + (promo ? '?attr=promo' : '')}
                                          onClick={closeMenu}>
                                        {child['title_' + Lang]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}

            </React.Fragment>
        ))}
        </>
    )
}

function linkUrlSplit(url) {
    let pieces = url.split("-");
    let newUrl = '';

    if (pieces.length > 2) {
        newUrl = pieces[0];
        pieces.splice(0, 1)
        newUrl = newUrl + "/" + pieces.join("-")
    } else {
        newUrl = pieces.join("/")
    }

    return newUrl
}

function displayMenu(e) {
    if (e.currentTarget.children[1]) {
        e.currentTarget.children[1].classList.add(styles.open)
    }
}

function closeMenu() {
    let items = document.getElementsByClassName(styles.submenu)
    Array.from(items).forEach((el) => {
        el.classList.remove(styles.open)
    });
}

export default Menu