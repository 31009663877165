import React from "react";
import {useTranslation} from 'react-i18next';
import {useDataState} from "../../../state/context";
import styles from './index.module.scss';

const Total = (props) => {
    const {t} = useTranslation();
    const { cart } = useDataState();

    return (
        <div className={styles.cart}>
            {props.title ? (
                <h3>Детали заказа</h3>
            ):null}
            <ul className={`${styles.cart_items} ${props.title ? null:styles.cart_checkout}`}>
                <li className={styles.cart_items_item}>
                    <span className={styles.cart_items_item_label}>{t("summ")}</span>
                    <span className={styles.cart_items_item_value}>{cart.baseTotal} {t("currency")}</span>
                </li>
                {typeof cart.sales.card !== 'undefined' && cart.sales.card > 0? (
                    <li className={styles.cart_items_item}>
                        <span className={styles.cart_items_item_label}>{t("card_discount")}</span>
                        <span className={styles.cart_items_item_value}>{cart.sales.card} {t("currency")}</span>
                    </li>
                ):null}

                {typeof cart.sales.promocode !== 'undefined' && cart.sales.promocode > 0? (
                    <li className={styles.cart_items_item}>
                        <span className={styles.cart_items_item_label}>{t("promocode_discount")}</span>
                        <span className={styles.cart_items_item_value}>{cart.sales.promocode} {t("currency")}</span>
                    </li>
                ):null}

                {typeof cart.sales.online !== 'undefined' && cart.sales.online > 0? (
                    <li className={styles.cart_items_item}>
                        <span className={styles.cart_items_item_label}>{t("online_discount")}</span>
                        <span className={styles.cart_items_item_value}>{cart.sales.online} {t("currency")}</span>
                    </li>
                ):null}
                <li className={styles.cart_items_item}>
                    <span className={styles.cart_items_item_label}>{t("delivery")}</span>
                    <span className={styles.cart_items_item_value}>{cart.delivery} {t("currency")}</span>
                </li>
                <li className={styles.cart_items_item}>
                    <span className={styles.cart_items_item_label_total}>{t("total")}</span>
                    <span className={styles.cart_items_item_value_total}>{cart.total} {t("currency")}</span>
                </li>
            </ul>
        </div>
    );
}

export default Total
