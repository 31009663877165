import React from 'react';
import {useTranslation} from 'react-i18next';
import constants from "../../../../state/constants";
import {useDataState} from "../../../../state/context";
import {Link} from 'react-router-dom';
import Label from '../../../../ui/label/index'
import styles from './index.module.scss';

const Product = (props) => {
	const {t} = useTranslation();
    const {base} = useDataState();
    const discount = (props.product.old_price > 0) ?
        Math.round(((props.product.old_price - props.product.price) / props.product.old_price) * 100)
        : false
    const discoundLabelTop = props.product.online ? '6rem' : '1.5rem';

    return (
        <Link className={styles.item} to={'/' + base.currentLang + props.product.link}>
            { props.product.online ? (
                <Label text="Только <br /> онлайн" color="#00aef2" top="1.5rem" size="1rem"/>
            ) : null}

            { discount ? (
                <Label text={'-' + discount + '%'} color="red" top={discoundLabelTop} size="1.2rem"/>
            ) : null}

            <div className={styles.item_thumbnail}>
                <picture>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/webp/200-200' + props.product.image + '.webp'}
                        type="image/webp"/>
                    <source
                        srcSet={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}
                        type="image/jpeg"/>
                    <img className={styles.item_thumbnail_img} loading="lazy" alt={props.product['title_' + base.currentLang]}
                         src={constants.API_BASE_URL + '/uploads/products/web/200-200' + props.product.image + '.jpg'}/>
                </picture>
            </div>
            <h3>{props.product['title_' + base.currentLang]}</h3>
            <div className={styles.item_prices}>
              <span className={styles.item_prices_base}>
                {props.product.slug === 'pa00-01' ? '200 - 4000' : props.product.price}
                <span className={styles.item_prices_base_currency}>{t("currency")}</span>
              </span>
              { discount ? (
              <span className={styles.item_prices_old}>
                {props.product.old_price}
                <span className={styles.item_prices_old_currency}>{t("currency")}</span>
              </span>
              ) : null}
            </div>
        </Link>
    );
}

export default Product